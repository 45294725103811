export function header() {
  const searchInput = document.querySelector(
    ".header #s"
  ) as HTMLInputElement | null;

  if (searchInput) {
    // focus on the input field when toggling search
    const toggles = document.querySelectorAll(".header__search-toggle");
    toggles.forEach((el) => {
      el.addEventListener("click", (e) => {
        // If we are on mobile, just go to the search page!
        if (window.innerWidth <= 950) {
          window.location.href = "/?s=";
          e.preventDefault();
        } else {
          setTimeout(() => {
            searchInput.focus();
            searchInput.select();
          });
        }
      });
    });
  }
}
