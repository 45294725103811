import Glider from "glider-js";
import { contextLogger } from "../../js/utils";

const logger = contextLogger("hero");

function autoPlay(
  slidesEl: HTMLElement,
  glider: Glider.Static<HTMLElement>,
  dotsEl: Element
) {
  let autoplayDelay = 4500;
  let timeout: NodeJS.Timeout;
  let hovering = false;
  let interacted = false;
  function startTimeout() {
    clearTimeout(timeout);
    if (interacted) {
      return;
    }
    timeout = setTimeout(() => {
      logger("switch slide", (glider.slide + 1) % glider.slides.length);
      if (!hovering)
        glider.scrollItem((glider.slide + 1) % glider.slides.length, false);
    }, autoplayDelay);
  }

  let animID = 0;
  const isAnimating = () => glider.animate_id !== animID;
  slidesEl.addEventListener("glider-animated", () => {
    logger("animated triggered");
    animID = glider.animate_id;
    if (!hovering) startTimeout();
  });

  slidesEl.addEventListener("mouseover", () => {
    logger("mouseover triggered");
    hovering = true;
    clearTimeout(timeout);
  });

  slidesEl.addEventListener("mouseout", () => {
    logger("mouseout triggered");
    hovering = false;
    if (!isAnimating()) startTimeout();
  });

  startTimeout();
  logger("start auto play...");

  dotsEl.querySelectorAll(".glider-dot").forEach((btn) => {
    btn.addEventListener("click", () => {
      logger("user clicked dots");
      interacted = true;
    });
  });
}

export function hero() {
  document.querySelectorAll(".component-hero").forEach((heroEl) => {
    logger(heroEl, "init slideshow");
    const slidesEl: HTMLElement | null = heroEl.querySelector(
      ".component-hero__slides--slideshow"
    );
    const dotsEl = heroEl.querySelector(".component-hero__dots");
    if (!slidesEl || !dotsEl) {
      logger("no slidesEl found...");
      return "";
    }
    logger("starting slide show");
    const glider = new Glider(slidesEl, {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: dotsEl,
      scrollLock: true,
      scrollLockDelay: 0,
      duration: 0.3,
    });
    autoPlay(slidesEl, glider, dotsEl);
  });
}
