import "../css/main";
import { stickyLink } from "../footer";
import { header } from "../header";
import { search } from "../search";
import { faqPosts } from "../templates/components/faq_posts";
import { hero } from "../templates/components/hero";
import { idFromHeaderDropdown } from "./id-from-header-dropdown";

document.addEventListener("DOMContentLoaded", () => {
  [hero, faqPosts, header, search, stickyLink, idFromHeaderDropdown].forEach(
    (c) => c()
  );
});
