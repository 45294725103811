export function search() {
  // add some button classes to the search button to make
  // it look like a search button!
  const submitButtons = document.querySelectorAll("#searchsubmit");
  submitButtons.forEach((el) =>
    el.classList.add(
      "component-button",
      "component-button--button",
      "component-button--primary"
    )
  );
}
