export function idFromHeaderDropdown() {
  const el = document.querySelector(".id-dropdown");
  let open = false;
  if (el) {
    const topBar = el.querySelector(".id-dropdown__top-bar");
    const inputs: NodeListOf<HTMLInputElement> =
      el.querySelectorAll(".id-input");

    function resetInputs() {
      inputs.forEach((inp) => {
        inp.value = "";
        inp.disabled = false;
      });
    }
    resetInputs();

    function openDropdown() {
      resetInputs();
      el?.classList.add("id-dropdown--open");
      // focus first input...
      inputs[0].focus();
      inputs[0].select();
    }

    function closeDropdown() {
      el?.classList.remove("id-dropdown--open");
      resetInputs();
    }

    function showLoader() {
      el?.querySelector(".lds-ellipsis")?.classList.add("lds-ellipsis--active");
    }

    if (topBar) {
      topBar.addEventListener("click", () => {
        open = !open;
        if (open) openDropdown();
        else closeDropdown();
      });
    }

    function submitForm() {
      // Make sure code is 6 chars between 0 and 9
      let code = "";
      inputs.forEach((x) => {
        if (x.value.charAt(0) >= "0" && x.value.charAt(0) <= "9")
          code += x.value;
      });
      if (code.length < 6) return;
      // Show loader, disable form, start redirect!
      showLoader();
      // First disable the form, show some kind of loading indicator...
      inputs.forEach((input) => {
        input.disabled = true;
      });
      setTimeout(() => {
        window.location.href = `/identifiering/${code}`;
      }, 1);
    }

    inputs.forEach((input, i) => {
      // Select on focus...
      input.addEventListener("focus", function () {
        this.select();
      });

      // Handle certain keys...
      input.addEventListener("keydown", (e) => {
        // Do nothing on tab or shift...
        if (e.key === "Tab") return;
        if (e.key === "Shift") return;
        // Close on escape...
        if (e.key === "Escape") {
          e.preventDefault();
          closeDropdown();
          return;
        }
        const t = e?.target as HTMLInputElement;
        let tempVal = t.value;
        if (e.key === "Backspace" && tempVal === "") {
          // Clear previous input and focus it...
          if (inputs[i - 1]) {
            inputs[i - 1].value = "";
            inputs[i - 1].focus();
          }
          e.preventDefault();
        }
      });

      // Handle change
      input.addEventListener("input", (e) => {
        const t = e?.target as HTMLInputElement;
        let tempVal = t.value;
        // Timeout is to make sure we
        // have the actual value in the input...
        setTimeout(() => {
          tempVal = input.value;
          if (tempVal.length > 1) {
            for (let j = 0; j < inputs.length; j++) {
              if (tempVal[j] === undefined) {
                setTimeout(() => {
                  inputs[i + j]?.focus();
                  inputs[i + j]?.select();
                }, 1);
                break;
              }
              const inp = inputs[i + j];
              inp.value = tempVal[j];
              // Are all inputs filled in?
              // If so submit!
              if (i + j === inputs.length - 1) return submitForm();
            }
          } else {
            input.value = tempVal.substring(0, 1);
          }
          // Is this the final box?
          if (i === inputs.length - 1) {
            return submitForm();
          } else {
            setTimeout(() => {
              if (input.value) {
                let done = false;
                inputs.forEach((inp, j) => {
                  // Just focus the next one...
                  if (!done && j > i) {
                    inp.focus();
                    inp.select();
                    done = true;
                  }
                });
              }
            });
          }
        }, 1);
      });
    });
  }
}
