let hasTapped = false;

export function stickyLink() {
  // Timeout is to allow rendering to appear before adding the class
  setTimeout(() => {
    document.querySelectorAll(".sticky-link").forEach((el) => {
      el.classList.add("sticky-link--inited");

      // Mobile specific stuff
      if (window.innerWidth < 820) {
        el.addEventListener("click", (e) => {
          const currentTarget = e.target as HTMLElement | null;

          if (currentTarget?.tagName === "IMG") {
            const currentFocusedEl =
              document.activeElement as HTMLElement | null;
            // If the img was clicked, do not use the link click default event...
            e.preventDefault();
            if (hasTapped) {
              currentFocusedEl?.blur();
            }
          }
          hasTapped = !hasTapped;
        });
      }
    });
  });
}
