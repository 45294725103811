function findActivePost(qs: string): string | null {
  // Do we have an activePost at all?
  const [, snd] = qs.split("activePost=");
  if (!snd) {
    return null;
  }
  const [fst] = snd.split("&");
  if (!fst) {
    return snd;
  }
  return fst;
}

export function faqPosts() {
  const activePost = findActivePost(window.location.search);
  if (activePost) {
    document.querySelectorAll(".component-faq-posts").forEach((el) => {
      const checkboxesToAvtivate: NodeListOf<HTMLInputElement> =
        el.querySelectorAll(
          `[data-faq-id="${activePost}"] input[type="checkbox"]`
        );
      // Active checkboxes to toggle open state...
      checkboxesToAvtivate.forEach((el) => {
        el.checked = true;
      });
      // Scroll into view!
      if (checkboxesToAvtivate.length > 0) {
        el.scrollIntoView(true);
      }
    });
  }
}
