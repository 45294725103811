// Import all your .scss files here!
import "./vars.styl";

// general
// import "./forms.scss";
import "./reset.styl";
import "../fonts/dagny/dagny.css";
import "./typography.styl";

// node_modules / plugins.
// import "./slick-carousel.scss";
// import "./ninja-forms.scss";
import "glider-js/glider.min.css";

// root templates
import "../header.styl";
import "../footer.styl";
import "../single.styl";
import "../search.styl";

// components
import "../templates/components/brand_assets.styl";
import "../templates/components/buttons.styl";
import "../templates/components/columns.styl";
import "../templates/components/faq_posts.styl";
import "../templates/components/free_text.styl";
import "../templates/components/form.styl";
import "../templates/components/hero.styl";
import "../templates/components/image.styl";
import "../templates/components/image_list.styl";
import "../templates/components/news_list.styl";
import "../templates/components/pop_out_image.styl";
import "../templates/components/privatforetag_choice.styl";
import "../templates/components/video.styl";
